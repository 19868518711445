<script lang="ts">
  import clsx from "clsx";
  import { ArrowRight } from "lucide-svelte";

  let perHour = false;

  function handleKeydown(event: KeyboardEvent) {
    if (event.key === "Enter" || event.key === " ") {
      perHour = !perHour;
      event.preventDefault();
    }
  }
</script>

<div class="border border-white/10 rounded-xl p-4 sm:p-8 bg-white/5">
  <div
    class="flex flex-col sm:flex-row gap-3 justify-center sm:justify-between items-center"
  >
    <h3 class="type-title-md">Compute costs</h3>
    <div
      class="relative flex items-center justify-between px-3 py-2 rounded-full cursor-pointer text-xs border border-white/20 gap-5"
      on:click={() => (perHour = !perHour)}
      on:keydown={handleKeydown}
    >
      <div
        class="absolute top-0 left-0 h-full rounded-full bg-primary/20 border border-primary transition-transform ease-out"
        style="transform: translateX({perHour ? '86px' : '0'}); width: {perHour
          ? '74px'
          : '90px'};"
      ></div>
      <div
        class={clsx(
          "font-medium transition-color",
          !perHour ? "text-primary" : "text-white/60",
        )}
      >
        Per Second
      </div>
      <div
        class={clsx(
          "font-medium transition-color",
          perHour ? "text-primary" : "text-white/60",
        )}
      >
        Per Hour
      </div>
    </div>
  </div>
  <div class="mt-8 space-y-7">
    <div class="space-y-3">
      <p class="font-medium">GPU</p>
      <div class="line-item">
        <p class="text-white/60">Nvidia H100</p>
        {#if perHour}
          <p class="price">
            <span class="line-through">$7.65</span>
            <ArrowRight size={16} class="inline text-white opacity-40" />
            $5.92 <span class="text-white/50">/ h</span>
          </p>
        {:else}
          <p class="price">
            <span class="line-through">$0.002125</span>
            <ArrowRight size={16} class="inline text-white opacity-40" />
            $0.001644 <span class="text-white/50">/ sec</span>
          </p>
        {/if}
      </div>
      <div class="line-item">
        <p class="text-white/60">Nvidia A100, 80 GB</p>
        {#if perHour}
          <p class="price">
            <span class="line-through">$5.59</span>
            <ArrowRight size={16} class="inline text-white opacity-40" />
            $4.75 <span class="text-white/50">/ h</span>
          </p>
        {:else}
          <p class="price">
            <span class="line-through">$0.001553</span>
            <ArrowRight size={16} class="inline text-white opacity-40" />
            $0.001319 <span class="text-white/50">/ sec</span>
          </p>
        {/if}
      </div>
      <div class="line-item">
        <p class="text-white/60">Nvidia A100, 40 GB</p>
        {#if perHour}
          <p class="price">
            <span class="line-through">$3.73</span>
            <ArrowRight size={16} class="inline text-white opacity-40" />
            $3.17 <span class="text-white/50">/ h</span>
          </p>
        {:else}
          <p class="price">
            <span class="line-through">$0.001036</span>
            <ArrowRight size={16} class="inline text-white opacity-40" />
            $0.000881 <span class="text-white/50">/ sec</span>
          </p>
        {/if}
      </div>
      <div class="line-item">
        <p class="text-white/60">Nvidia A10G</p>
        {#if perHour}
          <p class="price">$1.10 <span class="text-white/50">/ h</span></p>
        {:else}
          <p class="price">
            $0.000306 <span class="text-white/50">/ sec</span>
          </p>
        {/if}
      </div>
      <div class="line-item">
        <p class="text-white/60">Nvidia L4</p>
        {#if perHour}
          <p class="price">
            <span class="line-through">$1.05</span>
            <ArrowRight size={16} class="inline text-white opacity-40" />
            $0.80 <span class="text-white/50">/ h</span>
          </p>
        {:else}
          <p class="price">
            <span class="line-through">$0.000291</span>
            <ArrowRight size={16} class="inline text-white opacity-40" />
            $0.000222 <span class="text-white/50">/ sec</span>
          </p>
        {/if}
      </div>
      <div class="line-item">
        <p class="text-white/60">Nvidia T4</p>
        {#if perHour}
          <p class="price">$0.59 <span class="text-white/50">/ h</span></p>
        {:else}
          <p class="price">
            $0.000164 <span class="text-white/50">/ sec</span>
          </p>
        {/if}
      </div>
    </div>

    <div class="space-y-3">
      <p class="font-medium">CPU</p>
      <div class="line-item">
        <p class="text-white/60">Physical core (2 vCPU equivalent)</p>
        {#if perHour}
          <p class="price">
            <span class="line-through">$0.192</span>
            <ArrowRight size={16} class="inline text-white opacity-40" />
            $0.135 <span class="text-white/50">/ core / h</span>
          </p>
        {:else}
          <p class="price">
            <span class="line-through">$0.0000533</span>
            <ArrowRight size={16} class="inline text-white opacity-40" />
            $0.000038 <span class="text-white/50">/ core / sec</span>
          </p>
        {/if}
        <p class="text-xs text-white/50 sm:ml-auto">
          *minimum of 0.125 cores per container
        </p>
      </div>
    </div>

    <div>
      <div class="line-item">
        <p class="font-medium">Memory</p>
        {#if perHour}
          <p class="price">
            $0.024 <span class="text-white/50">/ GiB / h</span>
          </p>
        {:else}
          <p class="price">
            $0.00000667 <span class="text-white/50">/ GiB / sec</span>
          </p>
        {/if}
      </div>
    </div>
  </div>
</div>

<style lang="postcss">
  .line-item {
    @apply flex flex-col sm:flex-row sm:flex-wrap gap-x-1 gap-y-1 justify-between;
  }

  .price {
    @apply sm:ml-auto tabular-nums;
  }
</style>
